<template>
  <div>
    <!-- Miscellaneous Charts -->
    <b-row class="match-height">
      <!-- <b-col
        lg="2"
        cols="2"
      >
        <card-statistic-order-chart />
      </b-col> -->
      <b-col
        lg="12"
        cols="12"
      >
        <card-statistics-group />
      </b-col>
    </b-row>
    <!--/ Miscellaneous Charts -->

    <!-- Stats Card Vertical -->
    <!-- <b-row class="match-height">
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="EyeIcon"
          statistic="120k"
          statistic-title="Total orders"
          color="info"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="MessageSquareIcon"
          statistic="250"
          statistic-title="Active orders"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="danger"
          icon="ShoppingBagIcon"
          statistic="97.8k"
          statistic-title="Orders"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="primary"
          icon="HeartIcon"
          statistic="26"
          statistic-title="Cancelled orders"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="userIcon"
          statistic="689"
          statistic-title="Customers"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          hide-chart
          color="danger"
          icon="TruckIcon"
          statistic="2.1k"
          statistic-title="Returns"
        />
      </b-col>
    </b-row> -->

    <!-- Stats Card Horizontal -->
    <!-- <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="CpuIcon"
          statistic="86%"
          statistic-title="CPU Usage"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ServerIcon"
          color="success"
          statistic="1.2gb"
          statistic-title="Memory Usage"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ActivityIcon"
          color="danger"
          statistic="0.1%"
          statistic-title="Downtime Ratio"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="AlertOctagonIcon"
          color="warning"

          statistic="13"
          statistic-title="Issues Found"
        />
      </b-col>
    </b-row> -->

    <!-- Low Stock -->
    <b-overlay
      :show="loader"
      rounded="sm"
      variant="dark"
      :opacity="0.1"
    >
      <b-card title="Low stock items">
        <b-table
          :responsive="true"
          :busy.sync="isBusy"
          :items="items"
          :fields="fields"
          sticky-header="400px"
        >
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle" />
              <strong> Loading...</strong>
            </div>
          </template>
          <template #cell(ID)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(price)="data">
            &#163;{{ data.value }}
          </template>
        </b-table>
      </b-card>
    </b-overlay>

  </div>
</template>

<script>
// import StatisticCardVertical from '@/components/pages/dashboard/StatisticCardVertical.vue'
// import StatisticCardHorizontal from '@/components/pages/dashboard/StatisticCardHorizontal.vue'
import CardStatisticsGroup from '@/components/pages/dashboard/CardStatisticsGroup.vue'
// import CardStatisticOrderChart from '@/components/pages/dashboard/CardStatisticOrderChart.vue'
// import { kFormatter } from '@core/utils/filter'
import { createNamespacedHelpers } from 'vuex'

const mobileTabletModule = createNamespacedHelpers('mobileTablet')

export default {
  components: {
    // CardStatisticOrderChart,
    CardStatisticsGroup,
    // StatisticCardVertical,
    // StatisticCardHorizontal,
  },
  data() {
    return {
      // Area charts
      subscribersGained: [1, 2, 3],
      revenueGenerated: {},
      quarterlySales: {},
      ordersRecevied: {},

      // Line Charts
      siteTraffic: {},
      activeUsers: {},
      newsletter: {},

      isBusy: false,
      loader: false,
      fields: [
        { key: 'ID', label: 'ID' },
        { key: 'name', label: 'name' },
        { key: 'admin_name', label: 'admin name' },
        { key: 'price', label: 'price' },
        { key: 'quantity', label: 'quantity' },
        { key: 'order_time', label: 'Order time' },
        { key: 'mobile_models.name', label: 'mobile model' },
      ],
      items: [],
    }
  },
  async mounted() {
    await this.getParts()
  },
  methods: {
    ...mobileTabletModule.mapActions(['SHOW_ALL_PARTS', 'DELETE_PART']),

    async getParts() {
      try {
        this.isBusy = true
        this.items = await this.SHOW_ALL_PARTS()
        // Filter stock less then 10 in quantity
        this.items = this.items.filter(item => item.quantity < 10)
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
      }
    },
    // kFormatter,
  },
}
</script>
