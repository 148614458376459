<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <!-- <b-card-text class="mr-25 mb-0">
        Updated today
      </b-card-text> -->
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          md="3"
          sm="6"
          class="mb-2 mb-md-0"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="TrendingUpIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ orders.length }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Total orders
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          md="3"
          sm="6"
          class="mb-2 mb-md-0"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-primary"
              >
                <feather-icon
                  size="24"
                  icon="BoxIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ activeOrders }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Active orders
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          md="3"
          sm="6"
          class="mb-2 mb-md-0"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-danger"
              >
                <feather-icon
                  size="24"
                  icon="XCircleIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ cancelledOrders }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Cancelled orders
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          md="3"
          sm="6"
          class="mb-2 mb-md-0"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="UserIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ customers.length }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Customers
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const ordersModule = createNamespacedHelpers('orders')
export default {
  data() {
    return {
      orders: [],
      activeOrders: 0,
      cancelledOrders: 0,
      customers: [],
    }
  },
  async mounted() {
    await this.getOrders()
  },
  methods: {
    ...ordersModule.mapActions(['FETCH_ALL_ORDERS']),
    async getOrders() {
      try {
        this.orders = await this.FETCH_ALL_ORDERS()
        this.orders.forEach(element => {
          if (element.status === 'In House') {
            this.activeOrders += 1
          }
          if (element.status === 'Cancelled') {
            this.cancelledOrders += 1
          }
          this.customers.push(element.customer.email)
        })
        this.customers = this.customers.filter((c, index) => this.customers.indexOf(c) === index)
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
